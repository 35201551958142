import localConfig from './config-local';
import verandaConfig from './config-veranda';
import skyedConfig from './config-skyed';
import vapiConfig from './config-vapi';
import vstageapiConfig from './config-vstageapi';
import auapiConfig from './config-auapi';



var client = 'veranda'
var cfg = ''


if (client == 'local') {
    cfg = localConfig;
} else if (client == 'veranda') {
    cfg = verandaConfig;
} else if (client == 'skyed') {
    cfg = skyedConfig;
} else if (client == 'vapi') {
    cfg = vapiConfig;
}
else if (client == 'vstageapi') {
    cfg = vstageapiConfig;
}


export default cfg;